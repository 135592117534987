import React, {useState, useEffect} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { shareholderHistory } from '../Request/Histories';
import { Link } from 'react-router-dom';

import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';

const Shares = () => {

    const initialshareholder = {
        amount: 0,
        description: '',
        shareholderType: ''
    }
    const [shareholderForm, setshareholderForm] = useState(initialshareholder);
    const onChange = (e) =>
    setshareholderForm({ ...shareholderForm, [e.target.name]: e.target.value });
    const { isLoading: isSendingRequest, mutate: postshareholder } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/shareholder-application`, {
            type: shareholderForm.shareholderType
          });
        },
        {
          onSuccess: (res) => {            
            setSuccessMessage(res.data);
            triggerSuccess();
          },
          onError: (err) => {    
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );


      let myshareholderHistory = shareholderHistory();

      const [pendingshareholders, setPendingshareholder] = useState(0);
      const [activeshareholder, setActiveshareholder] = useState(0);
      const [completedshareholder, setcompletedshareholder] = useState(0);
      const getDate = (date) => new Date(date).toDateString();
  
      const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
      const setCurrentshareholder = (shareholder) => {
          localStorage.setItem('adminhistoryshareholderSelected', JSON.stringify(shareholder));
          const shareholderEvent = new Event('adminhistoryshareholderSelected');
          window.dispatchEvent(shareholderEvent);
      }
     

      useEffect(() => {
        document.title = 'Shareholders Application';
        myshareholderHistory = shareholderHistory();

        let pendingNum = 0;        
        let activeNum = 0;        
        let completedNum = 0;  

        myshareholderHistory.forEach(shareholder => {
                
                if (shareholder.status === 'approved') {
                    activeNum += parseInt(1);
                }
                if (shareholder.status === 'completed') {
                    completedNum += parseInt(1);
                }
                if (shareholder.status === 'declined') {
                    completedNum += parseInt(1);
                }
                if (shareholder.status === 'pending') {
                    pendingNum += parseInt(1);
                }
                if (shareholder.status === 'waiting approval') {
                    pendingNum += parseInt(1);
                }
            
        });
        
        setActiveshareholder(activeNum);
        setcompletedshareholder(completedNum);
        setPendingshareholder(pendingNum);
        
        if(window && window.init_template){
            window.init_template();
        }  
    }, [myshareholderHistory, activeshareholder, completedshareholder, pendingshareholders]);

  return (
    <div>
        <div className=' justify-content-center' style={{marginTop: "90px"}}>
       

        <div className="card bg-theme shadow-sm " style={{borderRadius: '10px'}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">BECOME A SHAREHOLDER</h1>
                        
                    </div>
                </div>
                
                <div className="card card-style bg-fade-yellow border border-fade-yellow alert alert-dismissible show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-info-square font-33 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-theme mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                Note: kindly note that only users who have participated in the <strong>last plan</strong> are eligible to apply.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <div className="content mt-0">
                    <div className="form-custom form-label form-icon">            
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>category</i>
                        <select onChange={onChange} name="shareholderType" className="form-select bg-theme color-theme rounded-xs" id="shareholderType" aria-label="choose shareholder type">
                            <option value="">Select Option </option>
                            <option value="Equity shareholders">Equity shareholders</option>
                            <option value="preference shareholders">preference shareholders </option>
                            <option value="Debenture shareholder">Debenture shareholder </option>
                        </select>
                        <label htmlFor="shareholderType" className="form-label-always-active color-highlight font-11">Choose Shareholder Type </label>
                    </div>
                    
                    <div className="pb-3"></div>
                </div>
                
                {/* <div className="alert bg-fade-red color-red-dark alert-dismissible rounded-s fade show" role="alert">
                   <i className="bi bi-exclamation-triangle pe-2"></i>
               </div> */}

                <div onClick={postshareholder} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
                {isSendingRequest ?<span>Applying...</span>
                :
                <span>Apply Now</span>
                }
                </div>
            </div>
        </div>


        <h2 className='text-center mb-2 mt-4'>
            Application History 
        </h2>
        <div className="card shadow-sm" style={{borderRadius: '9px'}}>
            <div className="content mb-0">
                <div className="tabs tabs-pill" id="lend-customers">
                    <div className="tab-controls rounded-m p-1 overflow-visible">
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
                        
                    </div>
                    <div className="mt-3"></div>
                    {/* <!-- Tab Bills List --> */}
                    <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!pendingshareholders > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No pending shareholder records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                myshareholderHistory.map(shareholder =>(
                                    shareholder.status === 'pending' &&
                                    <Link key={shareholder.id} data-bs-target="#admin-shareholder-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{shareholder.type}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(shareholder.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-red-dark">{shareholder.user.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">{shareholder.user.email}</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>

                <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!activeshareholder > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No active shareholder records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                myshareholderHistory.map(shareholder =>(
                                    shareholder.status === 'approved' &&
                                    <Link key={shareholder.id} data-bs-target="#admin-shareholder-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{shareholder.type}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(shareholder.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-green-dark">{shareholder.user.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">{shareholder.user.email}</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>
                
                </div>
            </div>
        </div>
    </div>
  )
}

export default Shares